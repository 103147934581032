import mobile from "../assets/app.png";
import desktop from "../assets/jewerly.png";

export const projectsImages = {
  mobile,
  desktop,
};

export const projectsTitle = "Responsive Web Design";
export const projectsLink = {
  link: "https://github.com/Suz8a",
  text: "SEE PROJECTS",
};
